/* eslint-disable no-restricted-syntax */
import { isNil } from 'lodash';

export const typeTypes = {
  ODDS: 'odds',
  odds: 'ODDS',
  STAKE: 'stake',
  stake: 'STAKE',
  POTENTIAL_PAYOUT: 'potentialPayout',
  potentialPayout: 'POTENTIAL_PAYOUT',
  FREE_STAKE: 'freeStake',
  freeStake: 'FREE_STAKE',
};

export const filtersOptions = {
  [typeTypes.ODDS]: {
    id: typeTypes.ODDS,
    label: 'Odds',
    field: 'odds.decimalValue',
  },
  [typeTypes.STAKE]: {
    id: typeTypes.STAKE,
    label: 'Stake',
    field: 'stake',
  },
  [typeTypes.POTENTIAL_PAYOUT]: {
    id: typeTypes.POTENTIAL_PAYOUT,
    label: 'Potential payout',
    field: 'potentialPayout',
  },
};

export const conditionTypeTypes = {
  LESS_THAN: 'LESS_THAN',
  LESS_OR_EQUAL: 'LESS_OR_EQUAL',
  EQUAL: 'EQUAL',
  GREATER_OR_EQUAL: 'GREATER_OR_EQUAL',
  GREATER_THAN: 'GREATER_THAN',
};

export const conditionTypeOptions = {
  [conditionTypeTypes.EQUAL]: {
    id: conditionTypeTypes.EQUAL,
    label: 'Equals (=)',
  },
  [conditionTypeTypes.LESS_THAN]: {
    id: conditionTypeTypes.LESS_THAN,
    label: 'Less than (<)',
  },
  [conditionTypeTypes.LESS_OR_EQUAL]: {
    id: conditionTypeTypes.LESS_OR_EQUAL,
    label: 'Less than or equal to (<=)',
  },
  [conditionTypeTypes.GREATER_THAN]: {
    id: conditionTypeTypes.GREATER_THAN,
    label: 'Greater than (>)',
  },
  [conditionTypeTypes.GREATER_OR_EQUAL]: {
    id: conditionTypeTypes.GREATER_OR_EQUAL,
    label: 'Greater than or equal to (>=)',
  },
};

export const getComparator = (conditionType) => {
  switch (conditionType) {
  case conditionTypeTypes.LESS_THAN:
    return (a, b) => a < b;
  case conditionTypeTypes.LESS_OR_EQUAL:
    return (a, b) => a <= b;
  case conditionTypeTypes.EQUAL:
    return (a, b) => a === b;
  case conditionTypeTypes.GREATER_OR_EQUAL:
    return (a, b) => a >= b;
  case conditionTypeTypes.GREATER_THAN:
    return (a, b) => a > b;
  default:
    console.warn(`No comparator for "${conditionType}" condition type`);
    return () => false;
  }
};

export const formatConditionType = (conditionType) => {
  switch (conditionType) {
  case conditionTypeTypes.LESS_THAN:
    return 'Less than';
  case conditionTypeTypes.LESS_OR_EQUAL:
    return 'Less than or equal to';
  case conditionTypeTypes.EQUAL:
    return 'Equals';
  case conditionTypeTypes.GREATER_OR_EQUAL:
    return 'Greater than or equal to';
  case conditionTypeTypes.GREATER_THAN:
    return 'Greater than';
  default:
    return '?';
  }
};

export const formatParameter = (parameter) => `"${parameter}"`;

export const formatConditionTypeAndParameter = (condtitionType, parameter) => {
  const { label = 'N/A' } = conditionTypeOptions[condtitionType] || {};
  const formattedParameter = formatParameter(parameter);
  return `${label} ${formattedParameter}`;
};

export const formatBetTickerFilterLabel = (betTickerFilter) => {
  /* const { label = 'N/A' } = filtersOptions[betTickerFilter.type] || {}; */
  if (isNil(betTickerFilter.parameter)) return 'N/A';
  const formattedCondtitionType = formatConditionType(betTickerFilter.conditionType);
  const formattedParameter = formatParameter(betTickerFilter.parameter);
  return `${formattedCondtitionType} ${formattedParameter}`;
};

export const formatBetTickerFilterHeaderLabel = (betTickerFilter) => filtersOptions[betTickerFilter.type]?.label || 'Undefined filter';
